

// Only phantom wallet
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';

require('@solana/wallet-adapter-react-ui/styles.css')

const root = ReactDOM.createRoot(document.getElementById('root'));

const phantomWallet = new PhantomWalletAdapter();

root.render(
  <React.StrictMode>
    <ConnectionProvider endpoint={"https://bold-flashy-silence.solana-mainnet.quiknode.pro/e2d96a7bb3e557f8010d78dce4ceb483d25e27eb/"}>
      <WalletProvider wallets={[phantomWallet]} autoConnect>
        <WalletModalProvider>
          <App />
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  </React.StrictMode>
);

reportWebVitals();
