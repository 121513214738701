import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Components/Header/Header";
import Header2 from "./Components/Header2/Header2";

import Footer from "./Components/Footer/Footer";

import Home_page from "./Components/Home_page/Home_page";

function App() {
  return (
    <div className="App">

      <Home_page />

      <Footer />
    </div>
  );
}

export default App;
