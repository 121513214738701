import React from "react";
import "./Roadmap.css";
import image5 from "../Assets/bday-mc.webp";
import image4 from "../Assets/king-best4.jpeg";

export default function Roadmap() {
  return (
    <div className="main_road_map" id="HowToBuy" style={{ marginRight: "0px" }}>
      <div className="container">
        <h1
          className="raod_head"
          style={{
            fontSize: "3.5rem",
            color: "#E9F50B",
            fontWeight: "900",
            marginRight: "0px",
          }}
        >
          How To Buy
        </h1>
        <div
          className="row"
          style={{ justifyContent: "center", marginTop: "30px" }}
        >
          <div className="col-md-12 site-font2 d-flex"  style={{ justifyContent: "center" }}>
            <p className="raod_para site-font2">
              <span
               className="widget"
              >
            Through the widget:
              </span>
              <span style={{ fontSize: "23px", marginBottom: "30px", display: "block" }}>
                Connect your wallet to our dedicated buy widget to buy $BDAY using SOL.
              </span>
              <span
               className="widget"
              >
           Through SOL Transfer:
              </span>
              <span style={{ fontSize: "23px", marginBottom: "20px", display: "block" }}>
                You can also join the $BDAY presale by sending SOL to:
              </span>
              <span style={{ color: "#E9F50B", fontSize: "23px", display: "block" }}>
                Wallet : 3ft4KrhWUnHmA3udpeWGskuDGTvT9S6QD6F21y2y9xgv
              </span>
            </p>
            {/* <p className="raod_para ">
            • BUY TAX: 0% <br></br>
            • SELL TAX: 0% <br></br>
            • FAIR LAUNCH
            </p> */}
            {/* <img src={image4} className="w-50 mt-3 mt-md-0"></img> */}
          </div>
          {/* <div
            className="col-md-6"
            style={{ justifyContent: "center", display: "flex" }}
          >
            <img src={image5} className="image-buy mt-3 mt-md-0"></img>
          </div> */}
        </div>
      </div>
    </div>
  );
}
