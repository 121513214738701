import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./Header2.css";
import { BsTelegram, BsTwitter } from "react-icons/bs";
import { AiOutlineMenu } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import xjuli from "../Assets/x-juli.png";
import tjuli from "../Assets/t-juli.svg";
import { Link } from "react-router-dom";

import buble from "../Assets/new-jackson-logo.png"
import buble2 from "../Assets/bub2-log.webp"

export default function Header() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="main_nav_bar"
        style={{ paddingRight: "3rem !important" }}
      >
        <Container className="onlyPc" style={{marginLeft:"40px"}}>
          <Navbar.Brand href="#home" className="logo_main_width">
            <div
              style={{
                alignItems: "center",
                fontSize: "20px",
                marginTop: "5px",
                marginBottom:"5px"
              }}
            >
              {/* <p style={{ transform: "rotate(-5deg)" , fontFamily: "East Sea Dokdo"}}>KINGJULIANA</p> */}
              <img src={buble}></img>
              {/* <img src={buble2} style={{width:"190px"}}></img> */}
            </div>
          </Navbar.Brand>
          {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
          <span className="d-block d-lg-none" onClick={() => setShow(!show)}>
            {show ? (
              <>
                <RxCross2 className="text-white fs-1" />{" "}
              </>
            ) : (
              <>
                <AiOutlineMenu className="text-white fs-1" />
              </>
            )}
          </span>
          {/* <div style={{border:"2px solid white"}}></div> */}
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className={show ? "show" : ""}
          >
        
            <Nav className="ms-auto gap-4">
              {/* <Nav.Link
                className="headeder_links gap_stakw"
                onClick={handleClose}
              >
           <Link to="staking_page" className="headeder_links text-decoration-none"> Staking </Link>
              </Nav.Link> */}

              {/* <Nav.Link
                className="headeder_links gap_stakw"
                onClick={handleClose}
                href="/"
             
             
              >
           Home
              </Nav.Link> */}

             
                
    

              <Nav.Link
                className="headeder_links gap_stakw  site-font2"
                // onClick={handleClose}
                href="https://www.bubblesjackson.com/"
                target="_blank"
              >
                Home
              </Nav.Link>

              {/* <Nav.Link
                className="headeder_links gap_stakw"
                onClick={handleClose}
                href="#Tokenomics"
              
              >
                Tokenomics
              </Nav.Link> */}

              <Nav.Link
                className="headeder_links gap_stakw site-font"
                onClick={handleClose}
                href="#HowToBuy"
              >
                How To Buy
              </Nav.Link>


      

              {/* <Nav.Link
                className="headeder_links gap_stakw"
                onClick={handleClose}
                href="https://bscscan.com/address/0xedff61e5de400485acb2d16592c714f0051302b6"
                target="_blank"
             
              >
           Smart Contract
              </Nav.Link> */}

              {/* <Nav.Link
                className="headeder_links gap_stakw"
                onClick={handleClose}
                href="#faq"
              >
                FAQ
              </Nav.Link> */}

              {/* 
              <NavDropdown
                title={<span>Home</span>}
                id="collapsible-nav-dropdown"
              >
                <NavDropdown.Item href="#about" onClick={handleClose}>
                  What is BTCETH
                </NavDropdown.Item>
                <NavDropdown.Item href="#how_to_buy" onClick={handleClose}>
                  How To Buy
                </NavDropdown.Item>
                <NavDropdown.Item href="#roadmap" onClick={handleClose}>
                  Roadmap
                </NavDropdown.Item>
                
                <NavDropdown.Item href="#Comparisons" onClick={handleClose}>
                  Comparisons
                </NavDropdown.Item>
                <NavDropdown.Item href="#faq" onClick={handleClose}>
                  FAQ
                </NavDropdown.Item>
              </NavDropdown> */}
              {/* <Nav.Link href="#pricing" className="headeder_links" onClick={handleClose}>English</Nav.Link> */}
            </Nav>

 
            <Nav className="ms-auto d-flex  align-items-center gap-2">
              <div className="social-icons d-flex gap-3 align-items center ">
                {/* <a href="https://t.me/lionnetwork_new">
                   <BsTelegram className="soical_icons"></BsTelegram>
                </a><br></br> */}

                {/* <BsTwitter className="soical_icons"></BsTwitter> */}
                {/* <a href="https://x.com/pepejobscoin" target="_blank">

                  <img src={xjuli} style={{width:"30px",height:"auto",color:"white"}}></img>

                  </a><br></br> */}
                {/* <span>
                  <svg
                    width="24"
                    height="20"
                    viewBox="0 0 24 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    _ngcontent-ng-c3098143052=""
                    aria-hidden="true"
                    className="x_logo"
                  >
                    <path
                      d="M1.05085 0L9.55754 10.4866L0.997238 19.0127H2.92397L10.4187 11.5478L16.474 19.0127H23.0302L14.0447 7.93638L22.0127 0H20.086L13.184 6.87483L7.60714 0H1.05085ZM3.8842 1.30836H6.89612L20.1965 17.7044H17.1846L3.8842 1.30836Z"
                      fill="black"
                      _ngcontent-ng-c3098143052=""
                    ></path>
                  </svg>
                </span> */}

                {/* <a href="https://t.me/pepejobscoin" target="_blank">
                  <img
                    src={tjuli}
                    style={{ width: "30px", height: "auto" }}
                  ></img>
                </a> */}
              </div>
              <div className="tree_btn d-flex gap-2">
                {/* <a href="https://bitcoinethtoken.io/whitepaper.pdf" target="_blank">
                <button className="Mmain_header_btn button-87 ">Whitepaper</button>

                </a> */}
                {/* <a href="https://twitter.com/BitcoinETHtoken" target="_blank">
                <button className="main_header_btn button-87 " >Twitter</button>

                </a> */}
                {/* <button className="main_header_btn">Twitter</button> */}
                {/* <button className="main_header_btn">Telegram</button> */}
                {/* <Link to="staking_page"> <button className="Mmain_header_btn button-87 ">Stake LION</button></Link> */}
              </div>
            </Nav>
                
          </Navbar.Collapse>
      
        </Container>
      </Navbar>
    </div>
  );
}
